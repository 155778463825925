/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
// @flow
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import imgServiceBanner from '../../images/service-hero.png';
import imgServiceBannerMobile from '../../images/service-hero-mobile.png';
import Breadcump from '../../components/Breadcrumb/Breadcrumb';
import { SITE_METADATA } from '../../constants';

import * as MediaCenterActions from '../../redux/mediaCenter/actions';
import Layout from '../../components/Layout/Layout';
import { TopBanner2, TopBannerText } from '../../components';
import BoxIntro from '../../components/BoxIntro';
import styles from '../../styles/pages/service.module.scss';

const IndexPage = ({ data, location }) => {
  const tags = decodeURIComponent(location.hash.replace('#', ''));

  const fansPage = useSelector((state) => state.mediaCenter.fansPage);
  const fansArticles = useSelector((state) => state.mediaCenter.fansArticles);
  const dispatch = useDispatch();

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getFansPageAsync());
    dispatch(MediaCenterActions.getFansArticlesAsync());
  }, [dispatch]);

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.service.title,
        description: SITE_METADATA.service.description,
      }}
    >
      <TopBanner2
        bgImg={imgServiceBanner}
        mobileBgImg={imgServiceBannerMobile}
        textAlign="center"
      >
        <TopBannerText>事業内容</TopBannerText>
      </TopBanner2>
      <Breadcump
        customLevels={[
          {
            path: '/',
            title: 'トップ',
          },
          {
            path: '/service/',
            title: '事業内容',
          },
        ]}
      />
      <BoxIntro
        titleStyle={{
          color: '#1c1c1c',
        }}
        title="あと払いサービス「ペイディ」"
        image={{
          url: require('../../images/home-intro-4.png'),
          alt: 'paidy app',
        }}
        imageStyle={{ marginLeft: '-20px' }}
        wrapperClassName={styles.wrapperService}
        description={
          <div className={styles.serviceContent}>
            ペイディが提供するのは、スマホだけで欲しいものを今すぐ購入できて、お支払いは「まとめてあとから」行っていただける「あと払い（Buy
            Now Pay Later）」サービス。
            <br />
            <br />
            シンプルなUX、翌月一括あと払いまたは分割手数料無料*
            の3・6・12回あと払いで支払いが可能な利便性、スマホで賢くお金の管理ができる点などから自分らしく使える「自由なあと払い」として多くの方にご利用いただいています。
            <br />
            <br />
            「夢に自信を持ち、心に余裕を持てる世界」を作ることを目指して。お客様への信頼をもとに、どなたでも安心してお買い物を楽しめる環境を整えることが、ペイディの大事な使命です。
            <p className={styles.notes}>*口座振替・銀行振込のみ無料</p>
          </div>
        }
        link={{
          to: 'https://paidy.com/',
          children: 'サービスページへ',
        }}
        secondLink={{
          to: 'https://paidy.com/merchant/',
          children: '導入を検討中の方はこちら',
        }}
      />
      <br />
      <br />
    </Layout>
  );
};

// $FlowFixMe
export default IndexPage;
